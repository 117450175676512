<template>
  <div class="learningObjectives h-100">
    <div class="container h-100 py-5">
      <h3
        class="text-capitalize pt-2 pt-md-5"
        data-aos="fade-down-right"
        data-aos-easing="ease-in-out"
        data-aos-duration="400"
      >
        {{ this.learning_objectives.Label }}
      </h3>
      <div
        class="
          row row-wrapper
          d-flex
          align-items-center
          justify-content-center
          pb-5
        "
      >
        <div class="row pt-md-4">
          <div
            class="col-md-8 pb-2 pb-md-5"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
          >
          <div class="card shadow">
            <div class="card-body p-3 p-md-5">
              <!-- <ul>
                <li
                  v-for="objective in learning_objectives.objectives"
                  :key="objective.id"
                  class="mb-2"
                >
                  {{ objective.value }}
                </li>
              </ul> -->
              <div>
                <span
                  v-html="this.learning_objectives.objectives"
                >
                </span>
              </div>
            </div>
          </div>
            
          </div>
          <div
            class="col-md-4 pb-5 pb-md-5"
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-duration="600"
          >
            <img
              class="banner w-100 rounded"
              src="~@/assets/images/learning_objectives.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Learning Objectives",
  data() {
    return {
      learning_objectives: [],
    };
  },
  methods: {
    getLearningObjectives() {
      this.learning_objectives = this.$store.getters["learningObjectives"];
    },
  },
  mounted() {
    this.getLearningObjectives();
    this.$store.dispatch( 'setInstructions', this.$store.getters['learningObjectives'].instructions )
    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch( 'setEndTime' , this.$options.name)
    next()
  }
};
</script>

<style scoped>
</style>